@import "~bootstrap/scss/bootstrap";
@import "fonts.scss";

/* Define a variable at the top level of the cascade */
:root {
  --theme-primary-color: blue;
}

body {
  background-color: #eaebf0;
  font-family: MatterWeb;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

.ModalOverlay,
.AlertOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0.3;
  transition: opacity 0.3s ease-in-out;
  z-index: 10;

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }
}

.Modal,
.Alert {
  z-index: 20;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  border-radius: 10px;
  padding: 2rem;
  overflow: visible;

  background-color: #fff;
  box-shadow: 0px 4px 20px 5px rgba(0, 0, 0, 0.13);

  &.Alert {
    width: 27rem;
    max-width: 95vw;
  }

  &.Modal {
    width: 37rem;
    max-width: 95vw;
  }

  max-height: 70vh;
  overflow-y: scroll;

  h2 {
    font-weight: bold;
    font-size: 1.56rem;
    color: #4b4b4b;
  }

  .BodyElement {
    margin-bottom: 1rem;
  }

  .Body {
    font-size: 0.875rem;
    line-height: 1.1875rem;
    color: #8f8f8f;
    overflow: visible;

    .Meeting {
      .Attribute {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 15px 0;

        .Key {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-right: 10px;
          padding-top: 6px;

          .Label {
            margin-left: 3px;
          }
        }
        .Value {
          flex: 1;
          display: flex;
          flex-direction: column;
          position: relative;

          input {
            align-self: stretch;
            background-color: #f4f4f4;
            border: none !important;
            outline: none !important;
            border-radius: 5px;
            padding: 8px 10px;

            &::placeholder {
              color: #c6c6c6;
            }
          }

          .react-datepicker__input-container input {
            width: 100%;
          }

          .User {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 5px 10px;
            margin-bottom: 5px;

            .Avatar {
              width: 28px;
              height: 28px;
              border-radius: 14px;
              margin-right: 5px;
            }
            span.Name {
              flex: 1;
            }
            button {
              border: none;
              outline: none;
              background: none;
            }
          }

          .UsersResult {
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            background: #fff;
            padding: 10px 0;
            border-radius: 5px;
            z-index: 200;
            max-height: 130px;
            overflow-y: scroll;

            .User {
              margin-bottom: 0px;
              cursor: pointer;
              &:hover {
                background-color: var(--theme-primary-color-hover);
                color: #fff;
              }
            }
          }
        }
      }
    }
  }

  .Footer {
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &.one {
      justify-content: center;
    }

    button {
      flex: 1;
    }

    button + button {
      flex: 1;
      margin-left: 10px;
    }
  }
}

.pill {
  //overflow: hidden;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #e0e1e3;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.05);
}

.Module {
  background-color: #fff;
  margin: 11px;
  border-radius: 10px;

  // Questions report type
  &.QuestionsReportModule {
    overflow-y: scroll;
    display: flex;
    flex: 1;
    align-self: stretch;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    padding: 25px;
    h2 {
      font-size: 1.56rem;
      color: #4b4b4b;
      font-weight: bold;
    }

    table {
      margin-top: 30px;
      align-self: stretch;

      td {
        &.Auto {
          width: 1px;
          white-space: nowrap;
        }
      }
      th + th,
      td + td {
        padding-left: 30px;
      }

      thead {
        th {
          padding-bottom: 10px;
        }
      }

      tbody {
        tr {
          border-bottom: 1px solid #f4f4f4;

          td {
            padding-top: 10px;
            padding-bottom: 10px;
          }
        }
      }
    }

    button {
      margin-top: 20px;
      align-self: center;
    }
  }

  // Right bar user detail
  &.MeetingsUser.sidebar {
    margin-top: 0;
    padding: 17px;

    .UserInfo {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 15px;

      .Avatar {
        width: 76px;
        height: 76px;
        border-radius: 38px;
      }
      h2.Name {
        font-size: 1.125rem;
        color: #333333;
        font-weight: bold;
        margin-top: 12px;
        margin-bottom: 3px;
      }
      h3.Subtitle {
        font-size: 1rem;
        line-height: 1.375rem;
        color: #8f8f8f;
        font-weight: normal;
      }
    }
    h4 {
      margin-top: 30px;
      font-size: 0.875rem;
      color: #4b4b4b;
      font-weight: normal;
    }
    p {
      font-size: 0.875rem;
      line-height: 1.375rem;
      color: #8f8f8f;
    }
    button {
      margin-bottom: 5px;
    }
  }

  // Users type
  &.Users,
  &.Conversations,
  &.Meetings {
    &.leftUnfolded {
      transform: translateX(-20rem) !important;
      opacity: 0.4 !important;
    }

    &.MeetingsUsers {
      margin-top: 0;
      margin-bottom: 0;
      padding-top: 0;

      .Header {
        padding: 0 11px 0 11px;
        display: flex;
        border-bottom: 1px solid #e5e5e5;
        margin-bottom: 1rem;

        span {
          display: none;
        }
        h3 {
          color: #333333;
          font-size: 1rem;
          font-weight: bold;

          padding: 1.25rem 6px;
          margin-bottom: -2px;
          position: relative;

          &.WithClick {
            cursor: pointer;
            color: #c6c6c6;

            &.Active {
              color: #333333;
              border-bottom: 2px solid #4b4b4b;
            }
          }

          .Badge {
            position: absolute;
            top: 10px;
            right: 0;
            width: 12px;
            height: 12px;
            border-radius: 6px;
            background-color: var(--theme-primary-color);
            color: #ffffff;
            font-size: 0.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        h3 + h3 {
          margin-left: 6px;
        }
      }

      .List {
        overflow-y: scroll;
      }
    }

    padding: 20px 0px;

    h4 {
      background-color: #f4f4f4;
      font-size: 1rem;
      font-weight: normal;
      text-transform: uppercase;
      height: 2.19rem;
      padding-left: 30px;
      margin-left: 0px;
      margin-right: 0px;
      display: flex;
      align-items: center;
      margin-bottom: 0;
    }

    .Search {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      // flex: 1;

      .SearchInput {
        border: 1px solid #e5e5e5;
        border-radius: 5px;
        outline: none;
        box-shadow: none;
        margin: 0 15px;
        padding: 7px 15px;

        &::placeholder {
          color: #8f8f8f;
        }
      }

      .Select {
        margin: 12px 15px;
      }

      p {
        margin: 10px 30px 20px 30px;
        font-size: 0.7rem;
        line-height: 1.2rem;
        color: #8f8f8f;
      }
    }

    .User {
      padding: 10px 30px;
      display: flex;
      flex-direction: row;
      align-items: center;

      cursor: pointer;

      .Avatar {
        width: 56px;
        min-width: 56px;
        height: 56px;
        border-radius: 28px;
        margin-right: 10px;
      }

      .UserText {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: hidden;

        h5 {
          color: #333333;
          font-size: 1rem;
          font-weight: normal;
          margin-bottom: 2px;
        }
        span {
          color: #8f8f8f;
          font-size: 0.8125rem;
          font-weight: normal;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .Meta {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-end;
        margin-right: -10px;
        margin-left: 10px;

        .Time {
          font-size: 0.8rem;
          color: var(--theme-primary-color);
        }

        .Unread {
          margin-top: 5px;
          width: 18px;
          height: 18px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          background-color: #bd1f1f;
          color: #fff;
          font-weight: bold;
          font-size: 0.7rem;
        }
      }

      &.Active {
        background-color: #f4f4f4;

        // .UserText,
        // .Meta .Time {
        //   color: #fff !important;
        //   h5,
        //   span {
        //     color: #fff;
        //   }
        // }
      }
    }

    .LoadMore {
      margin: 15px 30px 0 30px;
    }
  }

  // Profile type
  &.Profile {
    transform: translateX(20rem);
    width: 20rem;
    position: absolute;
    margin-top: 0;
    margin-bottom: 0;
    top: 0;
    bottom: 0;
    right: 0px;
    width: 18.75rem;
    overflow: hidden;
    background-color: #fff;
    transition: width 0.3s ease-in-out;
    overflow-y: scroll;
    opacity: 0.4;

    &.leftUnfolded {
      transform: translateX(0rem);
      opacity: 1;
    }

    h2 {
      padding: 15px;
      font-size: 1.3rem;
    }

    .Fields {
      display: flex;
      flex-direction: column;
      padding: 15px;

      .Field {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;

        .ProfilePic {
          display: flex;
          flex-direction: column;
          align-items: center;

          .Avatar {
            width: 80px;
            height: 80px;
            border-radius: 40px;
            margin: 10px 0;
          }
        }

        .Label {
          font-size: 0.8rem;
          margin-bottom: 0.3rem;
        }

        input,
        textarea {
          background-color: #f1f2f3;
          border: none;
          outline: none;
          padding: 8px;
          resize: none;
          font-size: 0.9rem;
        }
      }
    }
  }
}

a {
  color: var(--theme-primary-color);

  &:hover {
    color: var(--theme-primary-color);
  }
}

.btn {
  border: none;
  border-radius: 5px;
  background: var(--theme-primary-color);
  color: #ffffff;
  font-size: 0.8125rem;
  line-height: 1.3125rem;
  padding-top: 7px;
  padding-bottom: 7px;
  font-weight: bold;

  &.btn-block {
    display: block;

    &.btn-lg {
      padding: 1rem 0;
      font-size: 1rem;
    }
  }

  &:hover {
    color: #ffffff;
    background-color: var(--theme-primary-color-hover);
  }

  &:focus {
    outline: none !important;
    box-shadow: none;
  }

  &.btn-outline {
    border: 1px solid var(--theme-primary-color);
    background: transparent;
    color: var(--theme-primary-color);
  }

  &.btn-disabled {
    opacity: 0.4;
    cursor: default !important;
    &:hover  {
      background-color: inherit;
    }
  }

  // &:disabled {
  //   background: none;
  //   border: 1px solid #e5e5e5;
  //   color: #8d8d8d;
  //   &:hover {
  //     color: #8d8d8d;
  //   }
  // }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;

    &:hover {
      background: var(--theme-primary-color);
    }
  }
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 14px;
  height: 14px;
  border: 1px solid #979797;
  border-radius: 100%;
  background: #fff;
}
[type="radio"]:checked + label:before {
  border-color: var(--theme-primary-color);
}

[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: "";
  width: 8px;
  height: 8px;
  background: var(--theme-primary-color);
  position: absolute;
  top: 50%;
  left: 3px;
  border-radius: 100%;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
}
[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0) translateY(-50%);
  transform: scale(0) translateY(-50%);
}
[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1) translateY(-50%);
  transform: scale(1) translateY(-50%);
}

.basic-thumb {
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 50%;
  margin-right: 0.42rem;
  background-color: var(--theme-primary-color);
  font-size: 0.6875rem;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.top-bar {
  background-color: #fff;
  padding: 0.75rem 25px 0.75rem 0.75rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .left {
    display: flex;
    flex-direction: row;

    align-items: center;

    .logo {
      width: 2rem;
      height: 2rem;
      border-radius: 0.625rem;
      background-color: var(--theme-primary-color);
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        image-rendering: -webkit-optimize-contrast;
      }
    }

    h1 {
      font-size: 1rem;
      font-weight: bold;
      color: #4b4b4b;
      margin: 0;
      margin-left: 0.625rem;
    }
  }

  .right {
    display: flex;
    flex-direction: row;
    align-items: center;

    .basic-thumb {
      margin-right: 0.42rem;
      cursor: pointer;
    }

    a {
      margin-right: 1rem;
      font-size: 0.875rem;
      color: #4b4b4b;
      font-weight: normal;

      &.active {
        color: var(--theme-primary-color);
      }

      &:last-child {
        margin-right: 0;
      }
    }

    .LangPicker {
      .ActiveLanguage {
        background-color: var(--theme-primary-color-light);
        color: var(--theme-primary-color);
        padding: 0 10px;
        height: 30px;
        border-radius: 15px;
        display: flex;
        align-items: center;
        cursor: pointer;

        span {
          position: relative;
          top: 1px;
          margin-right: 4px;
          &::before {
            color: var(--theme-primary-color);
          }
        }
      }
      ul {
        display: none;
        position: absolute;
        top: 56px;
        right: 25px;
        border-radius: 10px;
        border: 1px solid #eeeff3;
        background-color: #ffffff;
        padding: 1rem;
        z-index: 200;
        list-style-type: none;

        li {
          display: flex;
          align-items: center;
          cursor: pointer;

          .Indicator {
            margin-right: 10px;
            width: 12px;
            height: 12px;
            border-radius: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #c6c6c6;

            .Fill {
              background-color: var(--theme-primary-color);
              width: 6px;
              height: 6px;
              border-radius: 3px;
            }
          }
        }

        li + li {
          margin-top: 8px;
        }

        &.Active {
          display: block;
        }
      }
    }
  }
}

.empty-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.message-box {
  display: none;

  border: 1px solid #ffffff;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  font-size: 0.6875rem;
  color: #ffffff;
  justify-content: center;
  flex-direction: column;

  p {
    margin-bottom: 0;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
      font-size: 1rem;
    }
  }

  &.show {
    display: flex;
  }
}

.login {
  display: flex;
  flex-direction: column;
  .logo {
    height: 9.25rem;
    padding: 1.3rem 4rem;
    margin-bottom: 2rem;
    background-origin: content-box;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    image-rendering: -webkit-optimize-contrast;
  }
  h2 {
    font-size: 1.5625rem;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
    max-width: 21.56rem;
  }
  h3 {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: normal;
    color: #ffffff;
    opacity: 0.7;
    text-align: center;
    max-width: 21.56rem;
  }

  .login-form {
    margin-top: 2rem;
    margin-bottom: 1rem;
    width: 21.56rem;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 0.625rem;

    input {
      padding: 0.9rem 0.375rem;
      margin: 0 0.625rem;
      border: none;
      outline: none;
      border-bottom: 1px solid #e9e9e9;

      &:last-child {
        border: none;
      }
    }
  }

  .btn {
    margin-top: 2rem;
    background-color: rgba(255, 255, 255, 0.3);
  }

  .help {
    display: block;
    font-size: 0.8125rem;
    color: rgba(255, 255, 255, 0.7);

    text-align: center;
    margin-top: 0.6rem;

    &.right {
      text-align: right;
    }

    span {
      opacity: 0.65;
    }
    a {
      color: #fff;
      opacity: 1 !important;
    }
  }
}

.outmostContainer {
  display: flex;
  flex-direction: row;

  .leftBar {
    margin-top: 25px;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background-color: #ffffff;
    align-self: flex-start;
    padding: 4px 23px 4px 0;

    @include media-breakpoint-down(md) {
      padding-right: 0;
      // position: fixed;
      // top: 55px;
      // left: 0px;
      // z-index: 999;
    }

    .item {
      display: flex;
      position: relative;
      flex-direction: row;
      align-items: center;
      border: none;
      padding: 6px 8px;
      font-size: 0.8125rem;
      color: #8f8f8f;
      text-decoration: none;

      span[class*="icon"] {
        font-size: 1.1rem;
        margin-right: 10px;
        @include media-breakpoint-down(md) {
          margin-right: 0;
        }
      }

      span[class*="icon"]:before {
        color: #8f8f8f;
      }

      span.Badge {
        position: absolute;
        left: 15px;
        top: -3px;
        background-color: #bd1f1f;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        font-size: 0.6rem;
        width: 16px;
        height: 16px;
        border-radius: 8px;
      }

      span.title {
        @include media-breakpoint-down(md) {
          display: none;
        }
      }

      &.active {
        color: var(--theme-primary-color);
        span:before {
          color: var(--theme-primary-color);
        }
      }
    }
  }

  .outer-container {
    flex: 1;
    // background-color: #fff;
    margin: 25px 25px 25px 10px;
    border-radius: 10px;
    overflow: hidden;
    min-height: calc(100vh - 120px);

    position: relative;

    &.FixedHeight {
      // CANVIAT DE MINHEIGHT A HEIGHT PEL HALL
      height: calc(100vh - 120px);
    }

    &.FullHeight {
      // CANVIAT DE MINHEIGHT A HEIGHT PEL HALL
      height: calc(100vh - 50px);
    }

    display: flex;
    flex-direction: row;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      justify-content: flex-start;
    }

    .curtain {
      border-radius: 10px;
      z-index: 1;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 10px;
      width: 18.75rem;
      overflow: hidden;
      background-color: #fff;
      transition: width 0.3s ease-in-out;
      overflow-y: scroll;

      &.folded {
        width: 0;
      }

      .inner {
        width: 18.75rem;
        padding: 1rem 0;
        display: flex;
        flex-direction: column;

        .top {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 1rem;
          margin-left: 1rem;
          margin-right: 1rem;

          h3 {
            font-size: 1.25rem;
            font-weight: bold;
            color: #4b4b4b;
          }

          a {
            font-size: 1.5rem;
            text-decoration: none;
          }
        }

        .daySelector {
          padding: 0 1rem 1rem 1rem;
          margin-right: 1rem;
          display: flex;
          flex-direction: row;
          overflow-x: scroll;
          &::-webkit-scrollbar {
            display: none;
          }

          .day {
            padding: 0.5rem 0.875rem;
            font-size: 0.6875rem;
            border-radius: 5px;
            border: 1px solid #e5e5e5;
            text-transform: uppercase;
            margin-right: 0.44rem;
            color: #8f8f8f;
            text-decoration: none;
            background-color: transparent;

            &.active {
              color: #4b4b4b;
              background-color: var(--theme-primary-color-light);
              border: none;
            }

            &:focus {
              outline: none;
            }

            &:last-child {
              margin-right: 2rem;
            }
          }
        }

        .day {
          .hour {
            .header {
              background-color: #f9f9fb;
              padding: 0.6875rem 1rem;
              font-size: 0.6875rem;
              color: #8f8f8f;
            }
            .appointments {
              padding: 1rem;

              .appointment {
                border: 1px solid #e5e5e5;
                border-radius: 5px;
                padding: 0.875rem 0.6875rem;
                margin-bottom: 1rem;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-end;
                text-decoration: none;

                &.active {
                  background-color: var(--theme-primary-color-light);
                  border: none;

                  .firstCol h4 {
                    font-weight: bold;
                  }
                }

                &:last-child {
                  margin-bottom: 0;
                }

                .firstCol {
                  h4 {
                    font-size: 0.8125rem;
                    line-height: 1.25rem;
                    margin-bottom: 0;
                    color: #4b4b4b;
                    font-weight: normal;
                  }

                  .time {
                    font-size: 0.6875rem;
                    line-height: 0.8rem;
                    color: #4b4b4b;

                    &.active {
                      color: var(--theme-primary-color);
                    }
                  }
                }

                .room {
                  display: block;
                  border-radius: 5px;
                  background-color: #f9f9fb;
                  font-size: 0.6875rem;
                  color: #8f8f8f;
                  padding: 0.25rem 0.5rem;
                  width: 38%;
                  margin-left: 0.3rem;

                  &.active {
                    background-color: var(--theme-primary-color);
                    color: #fff;
                  }
                }
              }
            }
          }
        }

        &.rooms {
          .btn {
            background-color: #f9f9fb;
            color: #8f8f8f;
            text-align: left;
            margin-bottom: 0.75rem;
            margin-left: 0.6875rem;
            margin-right: 0.6875rem;
            font-weight: normal;

            &.active {
              background-color: var(--theme-primary-color);
              color: #fff;
              font-weight: bold;
            }
          }
        }
      }
    }

    .curtainOverlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #000;
      opacity: 0.3;
      transition: opacity 0.3s ease-in-out;
      z-index: 11;

      &.folded {
        opacity: 0;
        pointer-events: none;
      }
    }

    .central {
      display: flex;
      z-index: 10;
      flex-direction: column;
      align-items: center;

      transform: translateX(20rem);
      opacity: 0.4;
      transition: all 0.3s ease-in-out;
      border-radius: 10px;
      overflow: hidden;

      @include media-breakpoint-up(lg) {
        flex: 1;
      }

      @include media-breakpoint-down(md) {
        margin-bottom: 1rem;
      }

      &.folded {
        opacity: 1;
        transform: translateX(0);
      }

      &.leftUnfolded {
        transform: translateX(-20rem);
        opacity: 0.4;
      }

      .CentralModule {
        flex: 1;
        align-self: stretch;
        margin-top: 0;
        margin-right: 0;
        margin-bottom: 0;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include media-breakpoint-down(md) {
          //flex: 0;
        }
      }

      .embed-outer {
        border-top-left-radius: 10px;
        overflow: hidden;
        width: 100%;

        // @media (min-width: 1490px) {
        //   margin-top: 25px;
        //   border-radius: 10px;
        // }

        .embed {
          overflow: visible;
          width: 100%;
          padding-top: 22.25%; /* 1:1 Aspect Ratio */
          //padding-top: 56.25%; /* 1:1 Aspect Ratio */
          position: relative; /* If you want text inside of it */

          &.vimeo {
            padding-top: 56.25%;
          }

          iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }

          &.llucana {
            padding-top: 56.25%;
          }
          &.whereby {
            padding-top: 56.25%;

            .toggleFull {
              font-size: 20px;
              &:before {
                color: #fff;
              }
            }
          }
          &.youtube {
            padding-top: 56.25%;
          }

          .reactions {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            top: 0;
            overflow: hidden;
            pointer-events: none;

            .emoji {
              position: absolute;
              bottom: -80px;
              opacity: 1;
              left: 50px;
              font-size: 60px;
              transition: all .400s linear;

              &.animated {
                bottom: 60%;
                opacity: 0;
              }
            }
          }

        }

        .emoji-wrap {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          .emojis {
            margin: 20px;
            display: flex;
            border-radius: 50px;
            padding: 2px;
            background-color: #ffffff;
            box-shadow: rgb(0 0 0 / 5%) 0px 0px 0px 1px, rgb(0 0 0 / 15%) 0px 1px 2px;

            .emoji {
              display: flex;
              flex-direction: column;
            }

            .emoji {
              font-size: 28px;
              width: 40px;
              height: 40px;
              border-radius: 20px;
              display: flex;
              justify-content: center;
              align-items: center;

              margin: 5px;
              cursor: pointer;
              transform-origin: bottom center;
              transition: all .2s ease-in-out;

              &:hover {
                transform: scale(1.4);
              }
            }

          }

          .counter {
            margin: 20px;
            display: flex;
            border-radius: 5px;
            background-color: #ffffff;
            box-shadow: rgb(0 0 0 / 5%) 0px 0px 0px 1px, rgb(0 0 0 / 15%) 0px 1px 2px;
            font-size: 12px;
            color: #999;

            .emoji {
              display: flex;
              align-items: center;
              margin: 0;
              padding: 10px 10px;

              .icon {
                margin-right: 6px;
              }
            }

            .emoji+.emoji {
              border-left: 1px solid #eee;
            }
          }
        }

        .fake-embed {
          background-color: #f6f6f9;
          border-bottom: 1px solid #e7e7e7;
          padding-top: 56.25%;
          position: relative;

          @media (min-width: 1490px) {
            border-bottom: none;
          }

          .fake-embed-inner {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .form-group {
              display: flex;
              flex-direction: column;
              padding: 10px 16px 15px 16px;
              overflow: hidden;

              input {
                border: none;
                padding: 0.5rem 0 1rem 0;
                width: 20rem;
                font-size: 0.8125rem;
                font-weight: bold;
                color: #555555;

                &:focus {
                  outline: none;
                }
              }
              button {
                width: 20rem;
              }
            }
          }
        }
      }

      .hallHeader {
        margin-top: -11px;
        display: flex;
        flex-direction: row;
        align-items: stretch;
        align-self: stretch;
        min-height: 280px;

        @include media-breakpoint-down(md) {
          flex-direction: column-reverse;
        }

        .hallInfo {
          flex: 1;
          padding: 2.18rem 1.75rem;

          .Header {
            display: flex;
            flex-direction: row;
            margin-bottom: 2rem;

            @include media-breakpoint-down(md) {
              flex-direction: column;
              align-items: center;
            }

            .Left {
              flex: 1;
              margin-right: 15px;
              @include media-breakpoint-down(md) {
                text-align: center;
              }
            }

            .Counter {
              border: 1px solid #eeeff3;
              border-radius: 10px;
              display: flex;
              flex-direction: row;
              align-self: flex-start;
              padding: 0 10px;

              @include media-breakpoint-down(md) {
                margin-top: 2rem;
                align-self: center;
              }

              .Element {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin: 10px 0px 10px 0px;
                width: 2.1rem;

                .Value {
                  font-size: 1.32rem;
                  color: #a5a5a5;
                }
                .Label {
                  font-size: 0.44rem;
                  text-transform: uppercase;
                  color: #a5a5a5;
                }
              }
            }
          }

          h2 {
            font-size: 1.5625rem;
            line-height: 1.625rem;
            color: #333333;
            font-weight: bold;
            margin-bottom: 0.2rem;
          }

          .Date {
            color: #9a9a9a;
            font-size: 0.81rem;
          }

          p {
            color: #4d4d4d;
            font-size: 1rem;
            line-height: 1.5rem;
          }
        }

        .hallBanner {
          flex: 1;
          padding: 1.3rem 1.75rem;
          background-origin: content-box;
          background-size: contain;
          background-position: center center;
          background-repeat: no-repeat;
          image-rendering: -webkit-optimize-contrast;

          @include media-breakpoint-down(md) {
            min-height: 10rem;
          }
        }
      }

      .hallColumns {
        flex: 1;
        overflow: hidden;
        //height: 400px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        align-self: stretch;

        @include media-breakpoint-down(md) {
          flex-direction: column;
          align-items: stretch;
        }

        .Module {
          flex-grow: 1;
          overflow-y: scroll;
          width: 0;
          padding: 20px 30px;
          position: relative;

          @include media-breakpoint-down(md) {
            width: inherit;
          }

          // &::after {
          //   content: "";
          //   position: absolute;
          //   bottom: 0;
          //   left: 0;
          //   height: 80px;
          //   width: 100%;
          //   background: red;
          //   background: linear-gradient(
          //     0deg,
          //     #ffffff 0%,
          //     rgba(255, 255, 255, 0) 100%
          //   );
          // }

          .Header {
            display: flex;
            flex-direction: row;
            margin-bottom: 20px;

            span {
              font-size: 1.4rem;
              color: var(--theme-primary-color);
              &:before {
                color: var(--theme-primary-color);
              }
            }

            h3 {
              color: #4b4b4b;
              font-weight: bold;
              font-size: 1.1rem;
              margin-left: 6px;
            }
          }
          // Static type
          &.Static {
            padding: 30px;

            .embed-outer {
              margin-bottom: 20px;
              border-radius: 10px;
            }
            img,
            video,
            iframe {
              max-width: 100%;
            }
          }

          &.Users {
            // Already generic module
            padding-left: 0;
            padding-right: 0;

            .Header {
              margin-left: 30px;
              margin-right: 30px;
            }
          }

          // Rooms type
          &.Rooms {
            .Room {
              display: block;
              margin-bottom: 20px;
              position: relative; /* If you want text inside of it */
              padding-top: 56.25%;
              border-radius: 10px;
              background: linear-gradient(
                0deg,
                #e8e8e8 0%,
                #f4f4f4 45%,
                #f4f4f4 100%
              );

              .RoomInner {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: flex-start;

                h4 {
                  padding-left: 1rem;
                  padding-bottom: 1.1rem;
                  color: #ffffff;
                  font-weight: bold;
                  font-size: 1.25rem;
                }
              }

              &.Image {
                background-size: cover;
              }

              &.NoImage .RoomInner h4 {
                color: #4b4b4b;
              }
            }
          }
        }
      }

      .info {
        // width: 100%;
        // padding: 3.5rem 1.75rem 4rem 1.75rem;
        // max-width: 650px;

        // @include media-breakpoint-down(lg) {
        //   padding: 3rem 1.75rem 3rem 1.75rem;
        // }

        width: 100%;
        max-width: 40rem;
        align-self: center;
        padding-top: 2rem;
        padding-bottom: 5.5rem;

        @include media-breakpoint-down(md) {
          padding-top: 0;
          padding-bottom: 0;
          padding: 0 10px 0 10px;
        }

        .Header {
          // display: flex;
          // flex-direction: row;
          // align-items: center;

          .RoomLogo {
            width: 100px;
            margin-right: 10px;
            display: block;
          }

          h2 {
            font-size: 1.5625rem;
            line-height: 1.625rem;
            color: #333333;
            font-weight: bold;
            margin-top: 1.5rem;
          }
        }

        .date {
          display: block;
          text-transform: capitalize;
          color: #8d8e8f;
          font-size: 0.8125rem;
          margin-bottom: 1.6rem;
          letter-spacing: 0.1px;
        }
        p {
          font-size: 1rem;
          line-height: 1.6rem;
          color: #4d4d4d;
        }
      }

      .MeetingsModule {
        padding: 25px;

        .MeetingsHeader {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start;
          align-self: stretch;

          h2 {
            color: #333333;
            font-size: 25px;
            font-weight: bold;
          }
        }

        .MeetingsGrid {
          display: flex;
          align-self: stretch;
          flex-direction: column;

          h3 {
            color: #4b4b4b;
            font-size: 1rem;
            font-weight: normal;
            margin-top: 40px;
            margin-bottom: 15px;
          }

          .Grid {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;

            .Meeting {
              background-color: #f9f9fb;
              width: unquote("calc(50% - 12.5px)");
              padding: 20px;
              margin-bottom: 25px;
              border-radius: 5px;

              &.Pending {
                border: 1px solid #e5e5e5;
                background-color: #ffffff;
              }

              &.Attending {
              }

              &.Old {
                opacity: 0.6;
              }

              .Header {
                padding-bottom: 25px;
                border-bottom: 1px solid #e5e5e5;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start;
                position: relative;

                h4 {
                  color: #555555;
                  font-weight: bold;
                  font-size: 1.125rem;
                }
                button {
                  border: none;
                  background: none;
                  outline: none;
                }
                ul.Dropdown {
                  position: absolute;
                  top: 30px;
                  right: 0;
                  background-color: #ffff;
                  border-radius: 5px;
                  list-style-type: none;
                  padding: 0;
                  overflow: hidden;
                  display: none;

                  &.Show {
                    display: block;
                  }

                  li {
                    padding: 6px 25px;
                    cursor: pointer;

                    &:hover {
                      background-color: var(--theme-primary-color-hover);
                      color: #fff;
                    }
                  }
                }
              }

              .Body {
                padding-top: 20px;

                .Attribute {
                  display: flex;
                  flex-direction: row;
                  align-items: flex-start;

                  .Key {
                    font-size: 0.8125rem;
                    color: #a4a4a4;
                  }

                  .Value {
                    margin-left: 5px;
                    font-size: 0.8125rem;
                    color: #606060;

                    .Participant {
                      display: flex;
                      flex-direction: row;
                      align-items: center;

                      .Icon {
                        padding-top: 2px;

                        &.Green::before {
                          color: #60d403;
                        }
                        &.Red::before {
                          color: #ff5959;
                        }
                      }

                      .ParticipantName {
                        margin-left: 3px;
                        font-size: 0.8125rem;
                      }
                    }
                  }
                }
              }

              .Footer {
                display: flex;
                flex-direction: row;
                padding-top: 25px;

                .react-add-to-calendar {
                  flex: 1;
                  position: relative;
                  .btn-calendar {
                    padding: 0;
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 100%;
                    width: 100%;

                    .react-add-to-calendar__button {
                      position: absolute;
                      left: 0;
                      top: 0;
                      height: 100%;
                      width: 100%;
                      padding: 0;
                      display: flex;
                      flex-direction: row;
                      justify-content: center;
                      align-items: center;
                      border: none;
                      text-decoration: none;
                      background-color: #ffffff;
                      border-radius: 5px;
                    }
                  }

                  .react-add-to-calendar__dropdown {
                    top: 100%;
                    width: auto;
                    min-width: 100%;
                    border: none;
                    background: #fff;

                    ul {
                      margin: 0;
                      padding: 0;
                    }
                  }
                }

                button {
                  flex: 1;
                  text-align: center;
                }
                .btn-outline-dark {
                  border-color: #e5e5e5;
                  color: #8f8f8f;
                }

                button + button,
                .AccessButton {
                  margin-left: 8px;
                }
              }
            }
          }
        }
      }

      .ChatModule {
        .Placeholder {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          span {
            font-size: 1.07rem;
            color: #8f8f8f;
          }

          span[class*="icon"] {
            font-size: 2.5rem;

            &:before {
              color: #8f8f8f;
            }
          }
        }

        .Chat {
          width: 100%;
          display: flex;
          flex: 1;
          flex-direction: column;

          .Header {
            display: flex;
            flex-direction: row;
            border-bottom: 1px solid #e5e5e5;
            padding: 20px 15px;
            align-items: center;

            .Title {
              font-size: 1.06rem;
              color: #333333;
              font-weight: bold;
              margin-left: 6px;
              margin-bottom: 2px;
            }
            span {
              font-size: 1.6rem;
              &::before {
                color: var(--theme-primary-color);
              }
            }
          }
          .Body {
            display: flex;
            flex: 1 1 auto;
            overflow-y: auto;
            height: 0px;
            flex-direction: column-reverse;
            padding: 0 23px 0 23px;
            overflow-y: scroll;

            .BodyInner {
              display: flex;
              flex-direction: column-reverse;
              padding-top: 15px;

              .Message {
                display: flex;
                flex-direction: row;
                margin-bottom: 15px;
                max-width: 55%;
                align-items: flex-start;

                .Avatar {
                  width: 50px;
                  height: 50px;
                  border-radius: 25px;
                  margin-right: 10px;
                }
                .Text {
                  padding: 1.25rem;
                  border-radius: 18px;
                  background-color: #eeeff3;
                  font-size: 0.875rem;
                  line-height: 1.375rem;
                }

                &.Mine {
                  align-self: flex-end;

                  .Text {
                    border-top-right-radius: 0;
                    background-color: var(--theme-primary-color);
                    color: #ffffff;
                  }
                }
                &.NotMine {
                  .Text {
                    color: #444444;
                    border-top-left-radius: 0;
                  }
                }
              }
            }
          }
          .Footer {
            align-self: stretch;
            padding: 0 23px 23px 23px;

            .questions-input-wrap {
              width: 100%;
              display: flex;
              flex-direction: column;
              margin-top: 10px;

              @include media-breakpoint-down(lg) {
                margin-top: 5px;
              }

              .questions-input {
                margin: 14px 16px 1px 16px;
                border: none;
                border-bottom: 1px solid #e5e5e5;

                font-size: 0.8125rem;
                font-weight: bold;
                color: #555555;
                padding-bottom: 8px;

                @include media-breakpoint-down(lg) {
                  margin: 8px 14px 1px 14px;
                }

                &:focus,
                &.active {
                  outline: none;
                  border-bottom: 2px solid var(--theme-primary-color);
                  margin-bottom: 0px;
                }
              }

              .questions-input-footer {
                margin: 8px 16px 5px 16px;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;

                @include media-breakpoint-down(lg) {
                  margin: 4px 12px 5px 12px;
                }

                span {
                  font-size: 0.8125rem;
                  line-height: 1rem;
                  color: #8f8f8f;
                  padding-top: 0.16rem;
                }

                button {
                  margin-left: 6px;
                  padding: 0;
                  border: none;
                  background: none;

                  &:focus {
                    outline: none;
                  }

                  .icon:before {
                    color: #8f8f8f;
                    font-size: 1rem;
                  }

                  &.active .icon:before {
                    color: var(--theme-primary-color);
                  }
                }
              }
            }
          }
        }
      }

      .NetworkingModule {
        flex-direction: row;
        align-items: stretch;

        .Module {
          margin-top: 0;
          margin-bottom: 0;
        }

        .Main {
          flex: 1;
          display: flex;
          flex-direction: column;
          overflow-y: scroll;

          .Header {
            padding: 0 11px 0 11px;
            display: flex;
            border-bottom: 1px solid #e5e5e5;
            margin-bottom: 1rem;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            .Left {
              display: flex;
              flex-direction: row;
              align-items: center;
            }

            span {
              //display: none;
            }
            h3 {
              color: #333333;
              font-size: 1rem;
              font-weight: bold;

              padding: 1.25rem 6px;
              margin-bottom: -2px;

              &.WithClick {
                cursor: pointer;
                color: #c6c6c6;

                &.Active {
                  color: #333333;
                  border-bottom: 2px solid #4b4b4b;
                }
              }
            }
            h3 + h3 {
              margin-left: 6px;
            }
          }

          .Meetings {
            overflow-y: scroll;
            display: flex;
            flex-direction: column;
            flex: 1;

            .Day {
              font-size: 0.8125rem;
              color: #4b4b4b;
              font-weight: bold;
              background-color: #eeeff3;
              padding: 0.5rem 1rem;
            }
            .Hour {
              font-size: 0.6875rem;
              color: #8f8f8f;
              font-weight: normal;
              background-color: #f9f9fb;
              padding: 0.59rem 1rem;
              margin-bottom: 15px;
            }
            .Meeting {
              border-radius: 10px;
              padding: 20px 15px 25px 15px;
              margin: 0 15px 15px 15px;
              background-color: #eeeff3;
              position: relative;

              &.Appointment {
                background-color: var(--theme-primary-color-light);
                &.Navigable {
                  cursor: pointer;
                }
              }

              &.Pending {
                border: 1px solid #e5e5e5;
                background: repeating-linear-gradient(
                  -45deg,
                  #fbfbfc,
                  #fbfbfc 35px,
                  #ffffff 35px,
                  #ffffff 70px
                );
              }

              &.Confirmed {
                cursor: pointer;
                .Status span {
                  color: #3fbc3a;
                }
                .Status span::before {
                  color: #3fbc3a;
                }
              }

              &.Waiting {
                .Status span {
                  color: #1d74cf;
                }
                .Status span::before {
                  color: #1d74cf;
                }
              }

              .MetaRow {
                display: flex;
                .Key {
                  font-size: 0.875rem;
                  color: #8f8f8f;
                }
                .Value {
                  font-size: 0.9375rem;
                  color: #333333;
                  margin-left: 10px;

                  &.Bold {
                    font-weight: bold;
                  }

                  &.Message {
                    font-size: 0.875rem;
                    color: #8f8f8f;
                  }

                  .Icon {
                    position: relative;
                    top: 1px;

                    &.Green::before {
                      color: #60d403;
                    }
                    &.Red::before {
                      color: #ff5959;
                    }
                  }

                  .Participant {
                    margin-right: 1rem;
                    margin-left: 0.2rem;
                    &.You {
                      font-weight: bold;
                    }
                  }
                }
              }

              .Actions {
                position: absolute;
                top: 15px;
                right: 10px;

                span {
                  font-size: 1.2rem;
                  cursor: pointer;

                  &::before {
                    color: #b5b5b7;
                  }
                }
                span + span {
                  margin-left: 5px;
                }
              }

              .Status {
                position: absolute;
                bottom: 6px;
                right: 10px;

                span {
                  font-size: 0.75rem;
                }

                span + span,
                .btn + .btn {
                  margin-left: 5px;
                }

                .Refuse {
                  background-color: #eeeff3;
                  color: #8f8f8f;
                  font-size: 0.75rem;
                  font-weight: normal;
                }
                .Accept {
                  background-color: #d5f3d4;
                  color: #4b4b4b;
                  font-size: 0.75rem;
                  font-weight: normal;
                }
              }
            }
          }

          .Body {
            display: flex;
            flex-direction: column;
            flex: 1;
            .Empty {
              display: flex;
              flex-direction: column;
              flex: 1;
              justify-content: center;
              align-items: center;

              span::before {
                font-size: 2rem;
                color: #8f8f8f;
              }
              p {
                margin-top: 0.7rem;
                color: #8f8f8f;
                max-width: 15rem;
                text-align: center;
                font-weight: bold;
              }
            }
          }

          .Footer {
            margin: 1rem;
            display: flex;
            flex-direction: row;

            .btn {
              flex: 1;
            }
            .btn + .btn {
              margin-left: 10px;
            }
          }
        }

        .Side {
          width: 21.2rem;
          transition: all 0.3s ease-in-out;
          overflow: hidden;

          &.UserDetail {
            margin-top: 0;
            width: 21.2rem;
            padding: 17px;

            .UserInfo {
              margin-top: 20px;
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-bottom: 15px;

              .Avatar {
                width: 76px;
                height: 76px;
                border-radius: 38px;
              }
              h2.Name {
                font-size: 1.125rem;
                color: #333333;
                font-weight: bold;
                margin-top: 12px;
                margin-bottom: 3px;
              }
              h3.Subtitle {
                font-size: 1rem;
                line-height: 1.375rem;
                color: #8f8f8f;
                font-weight: normal;
              }
            }
            h4 {
              margin-top: 30px;
              font-size: 0.875rem;
              color: #4b4b4b;
              font-weight: normal;
            }
            p {
              font-size: 0.875rem;
              line-height: 1.375rem;
              color: #8f8f8f;
              margin-top: 1rem;
            }
            button {
              margin-bottom: 5px;
            }
          }

          &.Folded {
            width: 0;
            margin: 0;
            padding: 0;
          }
        }

        .Placeholder {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          span {
            font-size: 1.07rem;
            color: #8f8f8f;
          }

          span[class*="icon"] {
            font-size: 2.5rem;

            &:before {
              color: #8f8f8f;
            }
          }
        }

        .Chat {
          width: 100%;
          display: flex;
          flex: 1;
          flex-direction: column;

          .Header {
            display: flex;
            flex-direction: row;
            border-bottom: 1px solid #e5e5e5;
            padding: 17px 15px;
            align-items: center;

            .Title {
              font-size: 1.06rem;
              color: #333333;
              font-weight: bold;
              margin-left: 6px;
              margin-bottom: 2px;
            }
            span {
              font-size: 1.6rem;
              &::before {
                color: var(--theme-primary-color);
              }
            }
          }
          .Body {
            display: flex;
            flex: 1 1 auto;
            overflow-y: auto;
            height: 0px;
            flex-direction: column-reverse;
            padding: 0 23px 0 23px;
            overflow-y: scroll;

            .BodyInner {
              display: flex;
              flex-direction: column-reverse;
              padding-top: 15px;

              .Message {
                display: flex;
                flex-direction: row;
                margin-bottom: 15px;
                max-width: 55%;
                align-items: flex-start;

                .Avatar {
                  width: 50px;
                  height: 50px;
                  border-radius: 25px;
                  margin-right: 10px;
                }
                .Text {
                  padding: 1.25rem;
                  border-radius: 18px;
                  background-color: #eeeff3;
                  font-size: 0.875rem;
                  line-height: 1.375rem;
                }

                &.Mine {
                  align-self: flex-end;

                  .Text {
                    border-top-right-radius: 0;
                    background-color: var(--theme-primary-color);
                    color: #ffffff;
                  }
                }
                &.NotMine {
                  .Text {
                    color: #444444;
                    border-top-left-radius: 0;
                  }
                }
              }
            }
          }
          .Footer {
            align-self: stretch;
            padding: 0 23px 23px 23px;

            .questions-input-wrap {
              width: 100%;
              display: flex;
              flex-direction: column;
              margin-top: 10px;

              @include media-breakpoint-down(lg) {
                margin-top: 5px;
              }

              .questions-input {
                margin: 14px 16px 1px 16px;
                border: none;
                border-bottom: 1px solid #e5e5e5;

                font-size: 0.8125rem;
                font-weight: bold;
                color: #555555;
                padding-bottom: 8px;

                @include media-breakpoint-down(lg) {
                  margin: 8px 14px 1px 14px;
                }

                &:focus,
                &.active {
                  outline: none;
                  border-bottom: 2px solid var(--theme-primary-color);
                  margin-bottom: 0px;
                }
              }

              .questions-input-footer {
                margin: 8px 16px 5px 16px;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;

                @include media-breakpoint-down(lg) {
                  margin: 4px 12px 5px 12px;
                }

                span {
                  font-size: 0.8125rem;
                  line-height: 1rem;
                  color: #8f8f8f;
                  padding-top: 0.16rem;
                }

                button {
                  margin-left: 6px;
                  padding: 0;
                  border: none;
                  background: none;

                  &:focus {
                    outline: none;
                  }

                  .icon:before {
                    color: #8f8f8f;
                    font-size: 1rem;
                  }

                  &.active .icon:before {
                    color: var(--theme-primary-color);
                  }
                }
              }
            }
          }
        }
      }
    }

    .sidebar {
      width: 19.1rem;
      margin-left: 20px;
      display: flex;
      flex-direction: column;

      @include media-breakpoint-down(md) {
        margin-left: 11px;
        margin-top: 15px;
      }

      transform: translateX(20rem);
      opacity: 0.4;
      transition: all 0.3s ease-in-out;

      &.folded {
        opacity: 1;
        transform: translateX(0);
      }

      &.leftUnfolded {
        transform: translateX(-20rem) !important;
        opacity: 0.4 !important;
      }

      @include media-breakpoint-down(lg) {
        width: 17.8rem;
      }

      @include media-breakpoint-up(xl) {
        width: 21.2rem;
      }

      @include media-breakpoint-down(md) {
        width: 100%;
        align-self: stretch;
      }

      .logo {
        height: 9.25rem;
        padding: 0.7rem 1.3rem;
        background-origin: content-box;
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        image-rendering: -webkit-optimize-contrast;
        border-radius: 10px;

        @include media-breakpoint-down(md) {
          margin-right: 12px;
        }
      }

      .sidebar-content {
        @include media-breakpoint-down(md) {
          margin-right: 12px;
        }
      }

      h3 {
        color: #4b4b4b;
        font-size: 1rem;
        line-height: 1rem;
        font-weight: bold;
        margin-bottom: 15px;
      }

      .marginated {
        margin-top: 15px;
        padding: 22px;
        border-radius: 10px;
        background-color: #ffffff;
        max-width: 100%;
      }

      .title {
        margin-bottom: 25px;
        display: flex;
        flex-direction: row;
        align-items: center;

        @include media-breakpoint-down(lg) {
          margin-bottom: 13px;
        }

        span::before {
          color: var(--theme-primary-color);
          font-size: 1.5rem;
          margin-right: 0.4rem;
        }

        h3 {
          margin-bottom: 0;
        }
      }

      .questions-wrap {
        .no-questions {
          padding: 1rem;
          border: 1px solid #e7e7e7;
          border-radius: 5px;
          margin-bottom: 10px;

          color: #4b4b4b;
          font-size: 0.8125rem;
          line-height: 1.3125rem;

          @include media-breakpoint-down(lg) {
            margin-bottom: 13px;
          }
        }

        .questions {
          list-style-type: none;
          padding: 0;
          margin: 0;
          margin-top: -10px;
          display: flex;
          flex-direction: column-reverse;
          height: 23rem;
          overflow-y: scroll;
          -ms-overflow-style: none;
          mask-image: -webkit-gradient(
            linear,
            left 20%,
            left top,
            from(rgba(0, 0, 0, 1)),
            to(rgba(0, 0, 0, 0))
          );

          @include media-breakpoint-down(lg) {
            margin-top: -5px;
          }

          &::-webkit-scrollbar {
            display: none;
          }

          .question {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 15px;

            .person {
              display: flex;
              flex-direction: row;
              .thumb {
                flex-shrink: 0;
                width: 22px;
                height: 22px;
                border-radius: 11px;
                margin-right: 10px;
                background-image: url("./avatar.svg");
                background-size: cover;
                display: inline-block;
              }
              .basic-thumb {
                width: 22px;
                height: 22px;
                border-radius: 11px;
                margin-right: 10px;
                font-size: 0.55rem;
              }
              .name {
                font-size: 0.8125rem;
                line-height: 1.25rem;
                font-weight: bold;
                color: #4b4b4b;
              }
            }

            .content {
              font-size: 0.8125rem;
              line-height: 1.25rem;
              margin-top: 0.1rem;
              margin-left: 32px;
              color: #4b4b4b;
            }

            @media not all and (min-resolution: 0.001dpcm) {
              display: block;

              @supports (-webkit-appearance: none) {
                .thumb {
                  display: inline-block;
                  position: relative;
                  top: 0.3rem;
                }
                .content {
                  display: inline;
                }
              }
            }
          }
        }

        .questions-input-wrap {
          width: 100%;
          display: flex;
          flex-direction: column;
          margin-top: 10px;
          padding: 1rem;
          padding-bottom: 0.4rem;

          @include media-breakpoint-down(lg) {
            margin-top: 5px;
          }

          h4 {
            color: #4b4b4b;
            font-size: 0.8125rem;
            font-weight: bold;
            margin-bottom: 1rem;
          }

          select {
          }

          .questions-input {
            border: none;
            background-color: #f9f9fb;
            border-radius: 5px;

            font-size: 0.8125rem;
            color: #8f8f8f;
            padding-top: 9px;
            padding-bottom: 9px;
            padding-left: 1rem;
            padding-right: 1rem;

            &:focus,
            &.active {
              outline: none;
            }
          }

          .questions-input-footer {
            margin-top: 8px;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;

            @include media-breakpoint-down(lg) {
            }

            span {
              font-size: 0.8125rem;
              line-height: 1rem;
              color: #8f8f8f;
              padding-top: 0.16rem;
            }

            button {
              margin-left: 6px;
              padding: 0;
              border: none;
              background: none;

              &:focus {
                outline: none;
              }

              .icon:before {
                color: #8f8f8f;
                font-size: 1rem;
              }

              &.active .icon:before {
                color: var(--theme-primary-color);
              }
            }
          }
        }
      }

      .survey-blocks-wrap {
        .no-surveys {
          padding: 1.56rem;
          border: 1px solid #e7e7e7;
          border-radius: 5px;
          margin-bottom: 1.68rem;

          p {
            color: #8f8f8f;
            font-size: 0.8125rem;
            text-align: center;
            margin: 0;
          }
        }

        .survey-block {
          padding: 16px;
          width: 100%;
          margin-bottom: 1rem;
          display: flex;
          flex-direction: column;

          h4 {
            font-size: 0.875rem;
            line-height: 1.1875rem;
            font-weight: bold;
            color: #4b4b4b;
            margin-bottom: 1rem;
          }

          &.voted {
            padding: 0.625rem 1rem;
            border: 1px solid #e7e7e7;
            border-radius: 5px;

            h4 {
              font-size: 0.6875rem;
              line-height: 1rem;
              font-weight: normal;
              color: #878788;
              margin-bottom: 5px;
            }
            p {
              margin: 0;
              font-size: 0.6875rem;
              line-height: 1.3125rem;
              font-weight: normal;
              color: #878788;
              span {
                color: #cdcdcd;
              }
            }
          }

          .survey-options-wrap {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            .survey-options {
              list-style-type: none;
              padding: 0;
              margin: 0;

              .option {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                margin-bottom: 10px;

                &.disabled label {
                  color: #e5e5e5;
                }

                label {
                  font-size: 0.8125rem;
                  line-height: 1.3125rem;
                  color: #4b4b4b;
                  margin: 0;
                }
                input {
                  margin-right: 8px;
                }
              }
            }
            button {
              margin-top: 15px;
            }
          }
        }
      }
    }
  }
}

.MeetingForm {
  margin: 0 1rem;
  flex: 1;

  .Field {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;

    label {
      color: #8f8f8f;
      font-size: 0.875rem;
    }

    p {
      color: #333333;

      a {
        font-weight: bold;
      }
    }

    input[type="text"],
    textarea {
      border: 1px solid #8f8f8f;
      border-radius: 5px;
      outline: none;
      padding: 7px 10px;
      resize: none;
    }

    ul {
      border: 1px solid #e5e5e5;
      border-radius: 5px;
      display: flex;
      flex-direction: row;
      list-style-type: none;
      padding: 5px 15px 15px 5px;
      flex-wrap: wrap;

      li {
        cursor: pointer;
        padding: 10px 14px;
        margin-left: 10px;
        margin-top: 10px;
        background-color: #f4f4f4;
        border: 1px solid #f4f4f4;
        border-radius: 5px;

        &.Unavailable {
          opacity: 0.3;
        }
        &.Selected {
          background: none;
          border-color: #8f8f8f;
        }
        &:not(.Selected):hover {
          background: #f9f9f9;
          border-color: #f9f9f9;
        }
      }
    }
  }
}

.Modal .MeetingForm {
  margin: 0;
}

@import "App2020.scss";

@import "embeds.scss";
