.hallColumns,
.CentralModule {
  .column2 {
    float: left;
    width: 50%;
    padding: 0px;
  }
  .row2:after {
    content: "";
    display: table;
    clear: both;
  }
  @media screen and (max-width: 600px) {
    .column2 {
      width: 100%;
    }
  }

  .column3 {
    float: left;
    width: 33.33%;
    padding: 0px;
  }
  .row3:after {
    content: "";
    display: table;
    clear: both;
  }
  @media screen and (max-width: 600px) {
    .column3 {
      width: 100%;
    }
  }

  .column4 {
    float: left;
    width: 25%;
    padding: 0px;
  }
  .row4:after {
    content: "";
    display: table;
    clear: both;
  }
  @media screen and (max-width: 600px) {
    .column4 {
      width: 100%;
    }
  }

  /* HOVERS 19 JUL 21 */
  .containerop {
    position: relative;
    width: 100%;
  }
  .imageop {
    opacity: 1;
    margin: auto;
    display: block;
    transition: opacity 0.5s ease;
    backface-visibility: hidden;
  }
  .middleop {
    transition: opacity 0.5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
  }
  .containerop:hover .imageop {
    opacity: 0.5;
  }
  .containerop:hover .middleop {
    opacity: 1;
  }
  .textop {
    background-color: #aaa;
    color: white;
    font-size: calc(0.5em + 0.5vw);
    padding: 16px 32px;
    text-align: center;
  }
}
